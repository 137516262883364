<!--
 * @Description: 工单评价列表页面
 * @Author: 小广
 * @Date: 2019-06-12 17:09:25
 * @LastEditors: 小广
 * @LastEditTime: 2019-07-11 17:42:48
 -->
<template>
  <div class="OrderEvaluateList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #searchSlot>
        <v-datepicker label="评价时间段" type="rangedatetimer" :startTime.sync="searchParams.appriseStartTime" :endTime.sync="searchParams.appriseEndTime"></v-datepicker>
        <v-select label="回复状态" v-model="searchParams.isReply" :options="replyStatusOps"></v-select>
        <v-input label="订单编号" placeholder="请输入完整订单编号" v-model="searchParams.serialNumber"></v-input>
        <v-select label="评价类型" v-model="searchParams.sourceType" :options="sourceTypeOps"></v-select>
        <v-input label="服务质量" v-model="searchParams.quality"></v-input>
        <v-input label="服务速度" v-model="searchParams.speed"></v-input>
        <v-select label="是否二次修改" v-model="searchParams.appraiseAgain" :options="appraiseAgainOps"></v-select>
        <v-input label="用户姓名" placeholder="请输入完整姓名" v-model="searchParams.userName"></v-input>
        <v-select2 label="所属服务" v-model="searchParams.subId" v-bind="storeSubjectParams"></v-select2>
        <v-select2 label="商家名称" v-model="searchParams.shopId" v-bind="shopParams"></v-select2>
        <v-datepicker label="回复时间段" type="rangedatetimer" :startTime.sync="searchParams.replyStartTime" :endTime.sync="searchParams.replyEndTime"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button :text="scope.row.isReply === 0 ? '回复' : '查看'" type="text" permission="replyApprise" @click="look(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, getShopListURL, getStoreSubjectURL } from './api'
import { replyStatusOps, replyStatusMap, sourceTypeOps, appraiseAgainOps } from './map'
import moment from 'moment'
import { createAlinkVNode } from 'common/vdom'

export default {
  name: 'OrderEvaluateList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      replyStatusOps: replyStatusOps(1),
      sourceTypeOps: sourceTypeOps(1),
      appraiseAgainOps: appraiseAgainOps(1),
      shopParams: {
        searchUrl: getShopListURL,
        request: {
          text: 'busName',
          value: 'id'
        },
        method: 'post'
      },
      storeSubjectParams: {
        searchUrl: getStoreSubjectURL,
        request: {
          text: 'subName',
          value: 'subId'
        },
        method: 'post'
      },
      searchParams: {
        subHandle: 0,
        isReply: 0,
        sourceType: undefined,
        appraiseAgain: undefined,
        appriseStartTime: moment().subtract(7, 'd').format('YYYY-MM-DD HH:mm'),
        appriseEndTime: moment().format('YYYY-MM-DD HH:mm'),
        serialNumber: '',
        quality: '',
        speed: '',
        userName: '',
        subId: '',
        shopId: '',
        replyStartTime: '',
        replyEndTime: ''
      },
      headers: [
        {
          prop: 'serialNumberNode',
          label: '订单编号',
          minWidth: '150px',
          formatter: (row, prop) => {
            return createAlinkVNode(this, row, prop, {
              text: row.serialNumber,
              cb: this.look
            })
          }
        },
        {
          prop: 'sourceType',
          label: '评价类型'
        },
        {
          prop: 'showContent',
          label: '评价内容',
          formatter (row) {
            let content = row.appriseContent || ''
            if (content.length > 15) {
              content = `${content.substr(0, 15)}...`
            }
            return content
          }
        },
        {
          prop: 'quality',
          label: '服务质量',
          sort: '1'
        },
        {
          prop: 'speed',
          label: '服务速度',
          sort: '1'
        },
        {
          prop: 'subName',
          label: '所属服务'
        },
        {
          prop: 'shopName',
          label: '所属商家'
        },
        {
          prop: 'appraiseAgain',
          label: '是否二次修改',
          sort: '1'
        },
        {
          prop: 'userName',
          label: '用户姓名'
        },
        {
          prop: 'showAppraiseTime',
          label: '评价时间',
          formatter (row) {
            let change = row.appraiseAgain === '是' ? '(修改)' : ''
            return `${row.appraiseTime}${change}`
          }
        },
        {
          prop: 'isReplyStr',
          label: '状态',
          formatter (row) {
            return replyStatusMap[row.isReply]
          }
        }
      ]
    }
  },

  methods: {
    look (row) {
      this.$router.push({
        name: 'orderEvaluateForm',
        query: {
          id: row.id,
          serialNumber: row.serialNumber
        }
      })
    }
  }
}
</script>
