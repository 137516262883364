// 获取列表
const getListURL = `${API_CONFIG.baseURL}orderAppriseAction!viewList.action`
// 导出列表
const exportListURL = `${API_CONFIG.baseURL}orderAppriseAction!exportToExcel.action?subHandle=0`
// 获取商家名称列表
const getShopListURL = `${API_CONFIG.baseURL}serverCodewordAction!getShops.action`
// 获取所属服务列表
const getStoreSubjectURL = `${API_CONFIG.baseURL}communityStoreSubjectAction!viewSub.action`

// 查询详情
const queryURL = `${API_CONFIG.baseURL}orderAction!view.action`
// 回复评论
const replyURL = `${API_CONFIG.baseURL}orderAction!orderReply.action`

export {
  getListURL,
  exportListURL,
  getShopListURL,
  getStoreSubjectURL,
  queryURL,
  replyURL
}
